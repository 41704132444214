import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {AUTH, dbRef, storageRef} from "../../auth/FirebaseContext";
import {child, get, push, set, update} from "firebase/database";
import { getAllItemsOffline, putItemsOffline, updateItemOffline } from "../../store/offlineDb";
import {ref, uploadBytes} from "firebase/storage";

//TODO ispraviti apdejt slike i da se namesti da bude transactional
export const addItem = createAsyncThunk("addItem", async (payload) => {
  const { item, image } = payload;
  const itemKey = await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`), {
    ...item,
    inactive: false
  });
  if (image) {
    let imageRef = ref(ref(storageRef, "public/itemphotos"), `${itemKey.key}.png`);
    await uploadBytes(imageRef, image);
  } else {
    delete payload?.avatarUrl;
  }
  return { ...item, uid: itemKey.key };
});

export const changeItemPrice = createAsyncThunk("items/changePrice", async ({ uid, newPrice }) => {
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`), {
    price: Number(newPrice)
  });
});

export const changeItemName = createAsyncThunk("items/changeName", async ({ uid, name }) => {
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`), {
    name: name
  });
});

export const updateItem = createAsyncThunk("updateItem", async (payload) => {
  if (payload?.avatarUrl) {
    let imageRef = ref(ref(storageRef, "public/itemphotos"), `${payload.uid}.png`);
    await uploadBytes(imageRef, payload?.avatarUrl);
    delete payload?.avatarUrl;
  } else {
    delete payload?.avatarUrl;
  }
  await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${payload.uid}`), {
    ...payload,
    inactive: false
  });
  updateItemOffline({
    ...payload,
    inactive: false
  })
  return { ...payload, inactive: false };
});

export const deleteItem = createAsyncThunk("deleteItem", async (payload) => {
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${payload.uid}`), {
    ...payload,
    inactive: true
  });
  return { ...payload, inactive: !payload.inactive };
});

export const fetchAllMergedItems = createAsyncThunk("fetchAllMergedItems", async () => {
  const userId = AUTH.currentUser.uid;
  const offlineItems = await getAllItemsOffline();
  const categories = {};
  const mergedItems = [];
  if(!offlineItems || offlineItems?.length === 0){
    const [privateItemsSnap, publicItemsSnap] = await Promise.all([
      get(child(dbRef, `users/${userId}/private/items`)),
      get(child(dbRef, `public/items`))
    ]);

    // Pretvaranje snapshota u niz objekata
    const allPrivateProducts = [];
    privateItemsSnap.forEach(item => {
      allPrivateProducts.push({
        ...item.val(),
        uid: item.key
      });
    });

    const publicProducts = [];
    publicItemsSnap.forEach(item => {
      publicProducts.push({
        ...item.val(),
        uid: item.key
      });
    });

    // Kreiranje mape za brži pristup javnim proizvodima
    const publicProductsMap = new Map();
    publicProducts.forEach(item => {
      publicProductsMap.set(item.uid, item);
    });

    allPrivateProducts.forEach(item => {
      const publicItem = publicProductsMap.get(item.uid);
      const mergedItem = publicItem ? {
        ...publicItem,
        ...item,
        price: item?.price > 0 ? item.price : 0
      } : item;

      if (mergedItem.name && mergedItem.code && mergedItem.price && mergedItem.inactive !== true) {
        mergedItems.push(mergedItem);
        categories[mergedItem?.category?.toUpperCase()] = true;
      }
    });

    await putItemsOffline(mergedItems);
  }else{
    offlineItems.forEach(item => {
      const mergedItem = {
        ...item,
        price: Number(item?.price || 0)
      };

      if (mergedItem.name && mergedItem.code && mergedItem.price && mergedItem.inactive !== true) {
        mergedItems.push(mergedItem);
        categories[mergedItem?.category?.toUpperCase()] = true;
      }
    });
  }
  return { items: mergedItems, categories: Object.keys(categories) };
});

export const addItemsFromCsv = createAsyncThunk("addItemsFromCsv", async (payload) => {
  const { data, overwrite } = payload;
  if (overwrite) {
    await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`), null);
  }
  const newProducts = [];
  const categories = {};
  for (const item of data) {
    try {
      const response = await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`), {
        uid: item.uid || null,
        name: item.name,
        category: item.category,
        vat: item.vat,
        unit: item.unit,
        price: Number(item.price),
        ean: item.ean || null,
        code: item.code
      });
      newProducts.push({
        ...item,
        uid: response.key
      });
      categories[item?.category?.toUpperCase()] = true;
    } catch (e) {
    }
  }
  return {
    items: newProducts,
    categories: Object.keys(categories),
    overwrite
  };
});

const initialState = {
  allItemsList: [],
  allItemsCategories: [],
  loading: false
};

export const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    addItemToRedux: (state, { payload }) => {
      state.allItemsList = [...state.allItemsList, payload];
    },
    changeReduxItemQuantity: (state, { payload }) => {
      const { uid, newQuantity } = payload;
      state.allItemsList = state.allItemsList.map(item =>
        item.uid === uid ? { ...item, quantity: newQuantity } : item
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMergedItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllMergedItems.fulfilled, (state, { payload }) => {
        const { items, categories } = payload;
        state.allItemsList = items;
        state.allItemsCategories = categories;
        state.loading = false;
      })
      .addCase(addItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(addItemsFromCsv.pending, (state) => {
        state.loading = true;
      })
      .addCase(addItem.fulfilled, (state, { payload }) => {
        const items = [...current(state.allItemsList)];
        items.push(payload);
        state.allItemsList = items;
        state.loading = false;
      })
      .addCase(updateItem.fulfilled, (state, { payload }) => {
        state.allItemsList = state.allItemsList.map(item =>
          item.uid === payload.uid ? payload : item
        );
        state.loading = false;
      })
      .addCase(deleteItem.fulfilled, (state, { payload }) => {
        state.allItemsList = state.allItemsList.filter(item => item.uid !== payload.uid);
        state.loading = false;
      })
      .addCase(addItemsFromCsv.fulfilled, (state, { payload }) => {
        const { items, categories, overwrite } = payload;
        state.allItemsList = overwrite ? items : [...state.allItemsList, ...items];
        state.allItemsCategories = overwrite
          ? categories
          : [...new Set([...state.allItemsCategories, ...categories])];
        state.loading = false;
      })
      .addCase(addItem.rejected, (state) => {
          state.loading = false;
        }
      )
      .addCase(updateItem.rejected, (state) => {
          state.loading = false;
        }
      )
      .addCase(deleteItem.rejected, (state) => {
          state.loading = false;
        }
      )
      .addCase(addItemsFromCsv.rejected, (state) => {
          state.loading = false;
        }
      )
      .addCase(changeItemPrice.fulfilled, (state, { meta }) => {
        const { uid, newPrice } = meta.arg;
        const items = [...current(state.allItemsList)];
        const index = items.findIndex(value => value.uid === uid);
        if (index !== -1) {
          items[index] = {
            ...items[index],
            price: Number(newPrice)
          };
          state.allItemsList = items;
        }
      }).addCase(changeItemName.fulfilled, (state, { meta }) => {
      const { uid, name } = meta.arg;
      const items = [...current(state.allItemsList)];
      const index = items.findIndex(value => value.uid === uid);
      if (index !== -1) {
        items[index].name = name;
        state.allItemsList = items;
      }
    });
  }
});

export const { addItemToRedux, changeReduxItemQuantity } = itemsSlice.actions;
export const itemsReducer = itemsSlice.reducer;
